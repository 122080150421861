import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from './useAxiosPrivate';
import { LOGIN_MESSAGE } from '../constants/queryKeys';

export default function useLoginMessage({
  enabled = true,
}: {
  enabled: boolean;
}) {
  const { i18n } = useTranslation();

  const axios = useAxiosPrivate();

  async function queryFn() {
    const res = await axios.get<string>(`/system-messages/login-message?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: [LOGIN_MESSAGE, i18n.language],
    queryFn,
    enabled,
  });
}
