import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { PriceData } from '../../api/_generated_';
import './PriceQueryResults.scss';
import { PriceResult } from '../OfferForm/PriceCalculator/PriceCalculator';

type Props = {
  data: PriceData | undefined;
  isLoading?: boolean;
};

const visibleAttributes: ([keyof PriceData, number])[] = [
  ['purchasePrice', 2],
  ['centralWarehouseCosts', 4],
  ['customsFee', 4],
  ['freightFee', 4],
  ['importServiceFee', 4],
  ['importServicePalpaFee', 4],
  ['suggestedBackDoorPrice', 4],
  ['actualMarginValue', 4],
  ['alcoholicBeverageTax', 4],
  ['retailContainerTax', 4],
  ['recyclingFee', 4],
  ['depositValue', 4],
  ['retailPriceWithoutVat', 4],
  ['vatAmount', 4],
  ['retailPrice', 2],
  ['retailPricePerLitre', 2],
];

export default function PriceQueryResults({
  data,
  isLoading,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="price_calculator">
      <div className="price_calculator__header">
        <span>{t('prices.label-price-calculator')}</span>
        <Button
          label={t('prices.cmd-calculate-price')}
          severity="success"
          icon="pi pi-calculator"
          size="small"
          loading={isLoading}
        />
      </div>
      <PriceResult data={data} isLoading={isLoading} attributes={visibleAttributes} />
    </div>
  );
}
