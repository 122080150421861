import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import useSystemMessages from '../../hooks/useSystemMessages';
import useDismissSystemMessage from '../../hooks/useDismissSystemMessage';
import { SystemMessageDto } from '../../api/_generated_';

export default function SystemMessages() {
  const { data } = useSystemMessages();

  const { mutate } = useDismissSystemMessage();

  const onRemove = (message: SystemMessageDto) => {
    mutate(message.id);
  };

  const contentWithCloseButton = (message: SystemMessageDto) => (
    <>
      <div>{message.body}</div>
      <Button icon="pi pi-times" rounded text aria-label="Dismiss" onClick={() => onRemove(message)} size="small" />
    </>
  );

  return (
    <div>
      {data?.map((message) => (
        <Message
          key={`system-message-${message.id}`}
          className="system-message"
          content={() => contentWithCloseButton(message)}
        />
      ))}
    </div>
  );
}
