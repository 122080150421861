import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { SystemMessageDto } from '../api/_generated_';
import { SYSTEM_MESSAGES } from '../constants/queryKeys';

export default function useDismissSystemMessage() {
  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  async function dismissSystemMessage(id: number | string | undefined) {
    const res = await axios.patch<void>(`/system-messages/dismiss/${id}`);
    return res.data;
  }

  return useMutation({
    mutationFn: dismissSystemMessage,
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: [SYSTEM_MESSAGES] });

      const prevData = queryClient.getQueryData<SystemMessageDto[]>([SYSTEM_MESSAGES]);

      if (prevData) {
        queryClient.setQueryData<SystemMessageDto[]>(
          [SYSTEM_MESSAGES],
          (old) => old?.filter((item) => item.id !== id),
        );
      }

      return { previousData: prevData };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([SYSTEM_MESSAGES], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries([SYSTEM_MESSAGES]);
    },
  });
}
