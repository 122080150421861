import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  AttributeStructureItem, PriceData, PriceDataParams, PriceQueryViewData,
} from '../../api/_generated_';
import DynamicField from '../DynamicField';
import { formDataToPriceDataParams, getDefaultFormValues, isPriceCalculationDisabled } from '../../utils/attribute';
import { getLangByI18nLocale } from '../../utils/helper';
import { DATE_FORMAT_PRIME } from '../../i18n/config';
import PriceQueryResults from './PriceQueryResults';
import {
  ALCOHOL_CONTENT,
  COUNTRY_OF_DELIVERY,
  CUSTOMS_FEE,
  FREIGHT_VOLUME,
  OFFER_TARGET,
  SALES_UNITS_PER_12_MONTHS,
  SMALL_SPECIAL_BATCH,
} from '../../constants/pim-attributes';

type Props = {
  data: PriceQueryViewData;
  results: PriceData | undefined;
  isLoading?: boolean;
  onSubmit: SubmitHandler<PriceDataParams>;
};

type KeyValuePair = {
  [key: string]: any;
};

export default function PriceQueryForm({
  data, results, isLoading, onSubmit,
}: Props) {
  const { t, i18n } = useTranslation();

  const { control, watch, handleSubmit } = useForm<KeyValuePair>({
    defaultValues: getDefaultFormValues({}, data?.priceQueryAttributes),
  });

  const onFormSubmit = (formData: KeyValuePair) => {
    const formDataCopy = formData;
    let startDate = dayjs().add(1, 'month').format('YYYY-MM-DD');
    if (formData.date) {
      startDate = dayjs(formData.date).format('YYYY-MM-DD');
    }

    if (formData[FREIGHT_VOLUME] === '3') {
      formDataCopy[SMALL_SPECIAL_BATCH] = '20'; // PEITTOTASO_PIENET_ERIKOISERAT
    }

    const body: PriceDataParams = formDataToPriceDataParams(
      startDate,
      formDataCopy,
    );

    onSubmit(body);
  };

  const getSpecialRule = (attribute: AttributeStructureItem) => {
    if (attribute.code === ALCOHOL_CONTENT) {
      return {
        validate: () => {
          if (isPriceCalculationDisabled(watch(), data.ethylMildLimit, data.mildAlcoholLimit)) {
            return t('validations.message-price-calculation-alcohol-content-limits');
          }

          return undefined;
        },
      };
    }

    return undefined;
  };

  const formValues = watch();

  const filteredAttributes = useMemo(() => data?.priceQueryAttributes?.filter((item) => {
    const offerTargetValue = formValues[OFFER_TARGET];

    if (item.code === OFFER_TARGET) {
      return true;
    }

    if (!offerTargetValue) {
      return false;
    }

    let blackListAttributes: string[] = [];
    if (offerTargetValue === '0') { // Alkon maahantuonti
      blackListAttributes = [];
    } else if (offerTargetValue === '1') { // DDP Alkon keskusvarasto
      blackListAttributes = [
        COUNTRY_OF_DELIVERY,
        CUSTOMS_FEE,
        FREIGHT_VOLUME,
      ];
    } else if (offerTargetValue === '2') { // DDP Alkon myymälät
      blackListAttributes = [
        COUNTRY_OF_DELIVERY,
        SALES_UNITS_PER_12_MONTHS,
        CUSTOMS_FEE,
        FREIGHT_VOLUME,
      ];
    }

    return !blackListAttributes.includes(item.code);
  }), [formValues, data?.priceQueryAttributes]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {filteredAttributes?.map((item) => (
        <DynamicField
          required
          key={item.code}
          control={control as any}
          attribute={item}
          name={item.code}
          watch={watch}
          rules={getSpecialRule(item)}
        />
      ))}
      <Controller
        control={control}
        name="date"
        render={({ field }) => (
          <div className="field">
            <label htmlFor={field.name}>
              {t('price-query.label-selection-date')}
            </label>
            <Calendar
              className="w-full mb-3"
              value={field.value}
              name={field.name}
              showIcon
              onChange={(e) => field.onChange(e.value)}
              locale={getLangByI18nLocale(i18n.language)}
              dateFormat={DATE_FORMAT_PRIME}
            />
          </div>
        )}
      />
      <PriceQueryResults data={results} isLoading={isLoading} />
    </form>
  );
}
