import React, { useEffect, useRef } from 'react';
import { Messages } from 'primereact/messages';
import useLoginMessage from '../../hooks/useLoginMessage';
import useSessionStorage from '../../hooks/useSessionStorage';

export default function LoginMessage() {
  const [showLoginMessage, setShowLoginMessage] = useSessionStorage('showLoginMessage', true);

  const { data: loginMessage } = useLoginMessage({
    enabled: showLoginMessage,
  });

  const msgs = useRef<Messages>(null);

  const onRemove = () => {
    setShowLoginMessage(false);
  };

  useEffect(() => {
    if (loginMessage) {
      msgs.current?.clear();
      msgs.current?.show({
        severity: 'info',
        summary: loginMessage,
        sticky: true,
        closable: true,
      });
    }
  }, [loginMessage]);

  if (!showLoginMessage) {
    return null;
  }

  return (
    <div className="login-message">
      <Messages ref={msgs} onRemove={onRemove} className="system-message" />
    </div>
  );
}
