import { UseQueryResult } from '@tanstack/react-query';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';
import { DashboardMessageDto, Offer, SampleRequestResource } from '../../api/_generated_';
import DashboardSkeletons from '../DashboardSkeletons';
import Card from '../Card';

type ReactQueryProps = UseQueryResult<SampleRequestResource[] | Offer[] | DashboardMessageDto[]>;

type Props = ReactQueryProps & HTMLAttributes<HTMLDivElement> & {
  title: string;
};

export default function DashboardSection({
  title,
  data,
  isLoading,
  isError,
  children,
  className,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  if (isLoading) {
    return <DashboardSkeletons />;
  }

  return (
    <Card className={className}>
      <h4 className="mb-3 text-lg">
        {title}
        {(data && data?.length > 0) && ` (${data.length})`}
      </h4>
      {isError ? (
        <Message severity="error" text={t('common.error.label-fetch-error')} className="w-full" />
      ) : (
        children
      )}
    </Card>
  );
}
