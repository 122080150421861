import dayjs from 'dayjs';
import i18next, { TFunction } from 'i18next';
import { UseFormSetValue } from 'react-hook-form';
import {
  AttributeGroup, AttributeStructureGroup, AttributeStructureItem,
  AttributeValue, AttributeValueAttributeTypeEnum, Offer, PriceDataParams, ProductChangeProposal,
} from '../api/_generated_';
import { ProductChange } from '../components/types/types';
import { getUserFriendlyFilename } from './helper';
import {
  PRODUCT_GROUP, PRODUCT_SUB_GROUP,
  OFFER_GROUP, CLOUSE_TYPE, CLOSURE_SUB_TYPE,
  PRODUCTION_AREA, PRODUCTION_SUB_AREA, COUNTRY_OF_ORIGIN,
  RETAIL_PRICE,
  O_PRICE_TIVA,
  ALCOHOL_CONTENT,
  ALCOHOL_TAX_GROUP,
  COUNTRY_OF_DELIVERY,
  CUSTOMS_FEE,
  OFFER_TARGET,
  PACKAGE_TAX,
  PACKAGING_TYPE_SPECIFIER,
  PRICING_GROUP,
  SALES_UNIT_SIZE,
  SALES_UNITS_PER_12_MONTHS,
  SMALL_SPECIAL_BATCH,
} from '../constants/pim-attributes';
import { OFFER_GROUP_ACCESSORIES, TAX_GROUP_ETHYL_OVER_2_8, TAX_GROUP_ETHYL_OVER_2_8_UNDER_10 } from '../constants/pim-attribute-options';
import { DATE_FORMAT } from '../i18n/config';

const SelectAttributeTypes: AttributeValueAttributeTypeEnum[] = [
  AttributeValueAttributeTypeEnum.Option,
  AttributeValueAttributeTypeEnum.Options,
  AttributeValueAttributeTypeEnum.Referenceoption,
  AttributeValueAttributeTypeEnum.Referenceoptions,
  AttributeValueAttributeTypeEnum.ReferenceEntity,
  AttributeValueAttributeTypeEnum.ReferenceEntityCollection,
];

const SimpleSelectAttributeTypes: AttributeValueAttributeTypeEnum[] = [
  AttributeValueAttributeTypeEnum.Option,
  AttributeValueAttributeTypeEnum.Referenceoption,
  AttributeValueAttributeTypeEnum.ReferenceEntity,
];

const MultiSelectAttributeTypes: AttributeValueAttributeTypeEnum[] = [
  AttributeValueAttributeTypeEnum.Options,
  AttributeValueAttributeTypeEnum.Referenceoptions,
  AttributeValueAttributeTypeEnum.ReferenceEntityCollection,
];

export function getEmptyChar() {
  return '-';
}

export function isSelectAttribute(type: AttributeValueAttributeTypeEnum) {
  return SelectAttributeTypes.includes(type);
}

export function isSimpleSelectAttribute(type: AttributeValueAttributeTypeEnum) {
  return SimpleSelectAttributeTypes.includes(type);
}

export function isMultiSelectAttribute(type: AttributeValueAttributeTypeEnum) {
  return MultiSelectAttributeTypes.includes(type);
}

export function getBareDateValue(data: any, format?: string) {
  const dateFormat = format || DATE_FORMAT;
  const date = dayjs(String(data));

  return date.isValid()
    ? date.format(dateFormat)
    : getEmptyChar();
}

export function getBareBooleanValue(data: any, t?: TFunction) {
  if (!t) {
    return String(data);
  }

  return (data === true || data === 'true') ? t('common.label-yes') : t('common.label-no');
}

export function getValue(
  attributeType: AttributeValueAttributeTypeEnum | undefined,
  value: any,
  t?: TFunction,
  dateFormat?: string,
) {
  if (value === undefined || value === null) {
    return getEmptyChar();
  }

  switch (attributeType) {
    case AttributeValueAttributeTypeEnum.Yesno:
      return getBareBooleanValue(value, t);
    case AttributeValueAttributeTypeEnum.Date:
      return getBareDateValue(value, dateFormat);
    default:
      return Array.isArray(value) ? value.join(', ') : String(value);
  }
}

export function getBareValue(
  attribute: AttributeValue | undefined,
  t?: TFunction,
  dateFormat?: string,
): string {
  if (!attribute) {
    return getEmptyChar();
  }

  const { data, attributeType } = attribute;

  return getValue(attributeType, data, t, dateFormat);
}

export function getLabel(attribute: AttributeValue | undefined): string | undefined {
  return attribute?.attributeLabel || `[${attribute?.code}]`;
}

export function getStructureItemLabel(
  attribute: AttributeStructureItem | undefined,
): string | undefined {
  if (attribute?.translation) {
    return attribute.translation;
  }

  const localizationKey = `custom-attributes.${attribute?.code}`;
  if (i18next.exists(localizationKey)) {
    return i18next.t(`custom-attributes.${attribute?.code}`);
  }

  return `[${attribute?.code}]`;
}

export function getAttributeValue(
  attribute: AttributeStructureItem,
  value: any,
  nullBooleanValue: false | null = false,
) {
  if (attribute.attributeType === AttributeValueAttributeTypeEnum.Yesno) {
    return value !== null && value !== undefined
      ? value
      : nullBooleanValue;
  }

  if (isMultiSelectAttribute(attribute.attributeType)) {
    if (!value) {
      return [];
    }

    return Array.isArray(value) ? value : [value];
  }

  if (isSimpleSelectAttribute(attribute.attributeType)) {
    return value || (attribute.options.length === 1 && attribute.required
      ? attribute.options[0].code
      : null);
  }

  if (attribute.attributeType === AttributeValueAttributeTypeEnum.Date) {
    if (value === undefined) {
      return null;
    }

    return dayjs(value).isValid()
      ? new Date(value)
      : null;
  }

  // TODO: Check if we use multiple currencies
  if (attribute.attributeType === AttributeValueAttributeTypeEnum.Price && Array.isArray(value)) {
    const eurItem = value?.find((item) => item.currency === 'EUR');
    return Number(eurItem?.amount);
  }

  if (attribute.attributeType === AttributeValueAttributeTypeEnum.Metric) {
    return typeof value === 'object' && value != null
      ? Number(value.amount)
      : value;
  }

  if (attribute.attributeType === AttributeValueAttributeTypeEnum.Numeric) {
    return value !== null && value !== undefined ? Number(value) : null;
  }

  if (attribute.attributeType === AttributeValueAttributeTypeEnum.Image
    || attribute.attributeType === AttributeValueAttributeTypeEnum.File) {
    return value || null;
  }

  return value || '';
}

export function getOfferDisplayValue(
  offer: Offer,
  attribute: AttributeStructureItem,
  t: TFunction,
) {
  if (attribute.code === 'productionPlants') {
    return offer.productionPlants?.map((plant) => plant.name).join(', ');
  }

  return getDisplayValue(offer.attributes, attribute, t);
}

export function getDisplayValue(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  values: { [key: string]: any } = {},
  attribute: AttributeStructureItem,
  t: TFunction,
) {
  const { attributeType, code, decimalsAllowed } = attribute;

  const data = values[code as string];

  if (data === null || data === undefined || data === '') {
    return getEmptyChar();
  }

  if (AttributeValueAttributeTypeEnum.Yesno === attributeType) {
    return getBareBooleanValue(data, t);
  }

  if (isSimpleSelectAttribute(attributeType)) {
    const option = attribute.options?.find((item) => item.code === data);
    return option?.translation || data;
  }

  if (isMultiSelectAttribute(attributeType)) {
    if (!data) {
      return getEmptyChar();
    }

    if (!Array.isArray(data)) {
      return String(data);
    }

    const options = data
      .filter((rawCode) => attribute.options?.find((item) => item.code === rawCode))
      .map((rawCode) => attribute.options?.find((item) => item.code === rawCode)?.translation);

    return options?.join(', ') || getEmptyChar();
  }

  if (AttributeValueAttributeTypeEnum.Date === attributeType) {
    return getBareDateValue(data);
  }

  if (AttributeValueAttributeTypeEnum.Numeric === attributeType) {
    return data.toLocaleString('fi-FI', {
      minimumFractionDigits: decimalsAllowed ? 1 : 0,
    });
  }

  if (AttributeValueAttributeTypeEnum.File === attributeType
    || AttributeValueAttributeTypeEnum.Image === attributeType) {
    return getUserFriendlyFilename(data);
  }

  return data ? String(data) : getEmptyChar();
}

export function getUniqueProposals(
  groups: AttributeGroup[] = [],
  changes: ProductChangeProposal[] = [],
): ProductChange[] {
  const values = groups.reduce((prev, next) => ({
    ...prev,
    ...next.values,
  }), {} as { [key: string]: AttributeValue; });

  const uniqueChanges = changes
    .map((item) => item.requestId)
    .filter((change, index, self) => self.indexOf(change) === index);

  return uniqueChanges.map((requestId) => {
    const allChanges = changes.filter((item) => item.requestId === requestId);
    return {
      requestId,
      created: allChanges[0].created,
      effective: allChanges[0].effective,
      supplier: allChanges[0].supplier,
      user: allChanges[0].user,
      editable: allChanges[0].editable,
      changes: allChanges.map((change) => ({
        code: change.attribute,
        newValue: change.value,
        label: values?.[change.attribute as string]?.attributeLabel,
        oldValue: values?.[change.attribute as string]?.data,
      })),
    };
  }).sort((a, b) => dayjs(b.created).toDate().getTime() - dayjs(a.created).toDate().getTime());
}

export function getFlatAttributes(groups: AttributeStructureGroup[] = []) {
  return groups.flatMap((group) => group.attributes);
}

export function getAttributeMap(attributes: AttributeStructureItem[] = []) {
  return attributes
    .reduce((prev, next) => ({
      ...prev,
      [next.code]: {
        ...next,
      },
    }), {} as { [key: string]: AttributeStructureItem; });
}

export function getDefaultFormValues(
  values: { [key: string]: object } = {},
  attributes: AttributeStructureItem[] = [],
  nullBooleanValue: false | null = false,
) {
  return attributes
    .reduce((prev, next) => ({
      ...prev,
      [next.code]: getAttributeValue(
        next,
        values[next.code],
        nullBooleanValue,
      ),
    }), {} as { [key: string]: any; });
}

export function getDownloadUrl(value: string, attributeCode?: string, productId?: string) {
  if (!value) {
    return '';
  }
  if (value.toString().startsWith('http://') || value.toString().startsWith('https://')) {
    return `/products/${productId}/media-files/${attributeCode}/download`;
  }
  return `/files/${value}`;
}

export function sortAttributeValues(values: { [key: string]: AttributeValue }) {
  return Object.fromEntries(
    Object.entries(values).sort(([, a], [, b]) => {
      if (a.groupSortOrder === b.groupSortOrder) {
        return a.sortOrder - b.sortOrder;
      }
      return a.groupSortOrder - b.groupSortOrder;
    }),
  );
}

export function isAttributeDisabled(attr: AttributeStructureItem): boolean {
  return attr.readOnly || (attr.value !== null && attr.value !== undefined);
}

export function clearSubFields(
  setValue: UseFormSetValue<Record<string, any>>,
  name: string | undefined,
) {
  if (name === PRODUCT_GROUP) {
    setValue(PRODUCT_SUB_GROUP, null);
  } else if (name === OFFER_GROUP) {
    setValue(PRODUCT_GROUP, null);
    setValue(PRODUCT_SUB_GROUP, null);
  } else if (name === CLOUSE_TYPE) {
    setValue(CLOSURE_SUB_TYPE, null);
  } else if (name === PRODUCTION_AREA) {
    setValue(PRODUCTION_SUB_AREA, null);
  } else if (name === COUNTRY_OF_ORIGIN) {
    setValue(PRODUCTION_AREA, null);
    setValue(PRODUCTION_SUB_AREA, null);
  } else if (name === O_PRICE_TIVA) {
    setValue(RETAIL_PRICE, null);
  }
}

export function isPriceCalculationDisabled(
  formData: { [key: string]: any },
  ethylMildLimit?: number,
  mildAlcoholLimit?: number,
): boolean {
  const isAccessory = formData[OFFER_GROUP] === OFFER_GROUP_ACCESSORIES;
  const alcoholContent = formData[ALCOHOL_CONTENT];
  const taxGroup = formData[ALCOHOL_TAX_GROUP];

  let isMild = false;

  if ((taxGroup === TAX_GROUP_ETHYL_OVER_2_8 || taxGroup === TAX_GROUP_ETHYL_OVER_2_8_UNDER_10)
      && alcoholContent <= (ethylMildLimit ?? 5.5)) {
    isMild = true;
  } else if ((taxGroup !== TAX_GROUP_ETHYL_OVER_2_8
    && taxGroup !== TAX_GROUP_ETHYL_OVER_2_8_UNDER_10)
        && alcoholContent <= (mildAlcoholLimit ?? 8.0)) {
    isMild = true;
  }

  return isAccessory || isMild;
}

export function formDataToPriceDataParams(
  selectionDate: string | undefined,
  formData: { [key: string]: any },
): PriceDataParams {
  return {
    startDate: selectionDate,
    purchasePrice: formData[O_PRICE_TIVA],
    alcoholContent: formData[ALCOHOL_CONTENT],
    pricingGroup: formData[PRICING_GROUP],
    salesUnitsPer12Month: formData[SALES_UNITS_PER_12_MONTHS],
    packagingTypeSpecifier: formData[PACKAGING_TYPE_SPECIFIER],
    salesUnitSize: formData[SALES_UNIT_SIZE],
    packageTax: formData[PACKAGE_TAX],
    customsFeeCode: formData[CUSTOMS_FEE],
    smallSpecialBatch: formData[SMALL_SPECIAL_BATCH],
    offerTarget: formData[OFFER_TARGET],
    countryOfDelivery: formData[COUNTRY_OF_DELIVERY],
    alcoholTaxGroup: formData[ALCOHOL_TAX_GROUP],
  };
}
