import { useEffect, useState } from 'react';

export default function useLocalStorage(key: string, initialValue: unknown) {
  const readValue = () => {
    try {
      const item = window.sessionStorage.getItem(key);
      if (item) {
        return isJSON(item) ? JSON.parse(item) : item;
      }
      return initialValue;
    } catch (error) {
      console.error(`Error reading sessionStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  const isJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const [storedValue, setStoredValue] = useState(readValue);

  useEffect(() => {
    try {
      const valueToStore = storedValue instanceof Function ? storedValue(storedValue) : storedValue;
      window.sessionStorage.setItem(key, typeof valueToStore === 'object' ? JSON.stringify(valueToStore) : valueToStore);
    } catch (error) {
      console.error(`Error setting sessionStorage key “${key}”:`, error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}
