import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';
import dayjs from 'dayjs';
import {
  AttributeStructureItem, DollyRequest, DollyRequestStatusEnum, Offer, TenderFrame, User,
} from '../api/_generated_';
import { CHANGE_OFFER_TENDER_ID, DEFAULT_TENDER_ID, PHOTOGRAPHY_SERVICE } from '../constants/pim-attributes';
import { DATE_FORMAT } from '../i18n/config';

export const skeletons = (count: number) => Array.from(Array(count).keys());

export function getDynamicFieldTooltip(attribute: AttributeStructureItem) {
  return {
    tooltip: attribute?.helpText?.trim() ? attribute?.helpText : undefined,
    tooltipOptions: { position: 'top' } as TooltipOptions,
  };
}

export function getSortOrder(order: number | null | undefined): string {
  if (!order) {
    return '';
  }

  return order === -1
    ? 'desc'
    : 'asc';
}

export function getLangByI18nLocale(i18nLanguage: string) {
  return i18nLanguage === 'en-US'
    ? 'en'
    : 'fi';
}

export function megaBytesToBytes(mb: number): number | undefined {
  if (Number.isNaN(mb)) {
    return undefined;
  }

  return 1048576 * mb;
}

export function formatBytes(bytes: number, decimals: number) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function getAvatarLabel(currentUser: User | null): string | undefined {
  const name = currentUser?.name?.split(' ');

  if (!name?.length) {
    return '';
  }

  return name[0][0];
}

export function formatPrice(
  price: number | undefined,
  currency?: string,
  locale?: string,
  fractionDigits?: number,
) {
  if (price === null || price === undefined || Number.isNaN(price)) {
    return null;
  }

  const euro = new Intl.NumberFormat(locale || 'fi-FI', {
    style: 'currency',
    currency: currency || 'EUR',
    minimumFractionDigits: fractionDigits,
  });

  return euro.format(price);
}

export function saveAsExcelFile(buffer: BlobPart, fileName: string) {
  import('file-saver').then((module) => {
    if (module && module.default) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
    }
  });
}

export function getUserFriendlyFilename(filename: string) {
  // Image is in PIM
  if (filename.startsWith('http://') || filename.startsWith('https://')) {
    const startIndex = filename.lastIndexOf('/');
    const afterSlash = startIndex + 1;
    if (startIndex > -1 && filename.length > afterSlash) {
      return filename.substring(afterSlash, filename.length);
    }
  }

  const prefix = filename.lastIndexOf('__');

  return prefix === -1
    ? filename
    : filename.substring(0, prefix);
}

export function formatDate(date: string) {
  return dayjs(date).format(DATE_FORMAT);
}

export function formatDateTime(date: string) {
  return dayjs(date).format('LLL');
}

export const isGeneralSelectionOffer = (offer: Offer) => offer?.tenderId !== DEFAULT_TENDER_ID
  && offer?.tenderId !== CHANGE_OFFER_TENDER_ID;

export const hasPhotoServiceSelected = (offer: Offer) => {
  const test = offer?.attributes?.[PHOTOGRAPHY_SERVICE];
  return !!test;
};

export const canDeleteDollyRequest = (dollyRequest: DollyRequest | undefined) => {
  const allowedStatuses: DollyRequestStatusEnum[] = [
    DollyRequestStatusEnum.Draft,
    DollyRequestStatusEnum.Sent,
  ];

  return dollyRequest && allowedStatuses.includes(dollyRequest?.status);
};

export const tenderHasLockedAttributes = (tender?: TenderFrame) => {
  if (!tender) {
    return false;
  }
  return (tender.sections || [])
    .map((section) => section.attributes)
    .flat().some((attr) => !!attr.lockDuration);
};
