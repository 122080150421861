import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-feather';
import dayjs from 'dayjs';
import useProductChange from '../../hooks/useProductChange';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import ViewHeader from '../../components/ViewHeader';
import IconBlock from '../../components/IconBlock';
import DifferenceList from '../../components/DifferenceList';
import { getDefaultFormValues, getFlatAttributes } from '../../utils/attribute';
import { DATE_FORMAT } from '../../i18n/config';

export default function ProductChangePreview() {
  const { t } = useTranslation();

  const { data, status } = useProductChange();

  const flatAttributes = getFlatAttributes(data?.sections);

  const currentValues = getDefaultFormValues(data?.values, flatAttributes, null);

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader
        title={t('products.title-product-change-details')}
      />
      <p className="font-medium">{t('products.label-effective-since')}</p>
      <IconBlock
        icon={<Calendar />}
        label={dayjs(data?.effective).format(DATE_FORMAT)}
        className="mb-5"
      />
      <p className="font-medium">{t('products.label-change-targets')}</p>
      <DifferenceList
        current={currentValues}
        changes={data?.proposals}
        sections={data?.sections}
      />
    </NetworkContainer>
  );
}
