import { useMemo } from 'react';
import './PriceCalculator.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import dayjs from 'dayjs';
import usePriceCalculator from '../../../hooks/usePriceCalculator';
import { formatPrice } from '../../../utils/helper';
import { Offer, PriceData, PriceDataParams } from '../../../api/_generated_';
import * as PimAttributes from '../../../constants/pim-attributes';
import { formDataToPriceDataParams, isPriceCalculationDisabled } from '../../../utils/attribute';
import { useOfferContext } from '../../../stores/OfferContext';

const visibleAttributes: ([keyof PriceData, number])[] = [
  ['purchasePrice', 2],
  ['retailPrice', 2],
  ['retailPricePerLitre', 2],
];

type PriceResultProps = {
  isLoading?: boolean;
  data?: PriceData;
  attributes: ([keyof PriceData, number])[];
};

export function PriceResult({ data, isLoading, attributes }: PriceResultProps) {
  const { t } = useTranslation();
  return (
    <div className="price_calculator__body">
      {data && attributes.map((attr) => (
        <PriceRow
          key={attr[0]}
          label={t(`prices.result-labels.${attr[0]}`)}
          value={data[attr[0]]}
          isLoading={isLoading}
          fractionDigits={attr[1]}
        />
      ))}
    </div>
  );
}

type RowProps = {
  label: string;
  value: number | undefined;
  fractionDigits: number | undefined;
  isLoading?: boolean;
};

export function PriceRow({
  label,
  value,
  fractionDigits,
  isLoading,
}: RowProps) {
  return (
    <div className="price_calculator__body__row">
      <span className="price_calculator__body__row__label">
        {label}
      </span>
      <span className="price_calculator__body__row__value">
        {isLoading
          ? <Skeleton width="50px" borderRadius="0.25rem" />
          : formatPrice(value, undefined, undefined, fractionDigits)}
      </span>
    </div>
  );
}

type Props = {
  offer: Offer
  formData: { [key: string]: any };
  onUpdateRetailPrice: (value?: number | null) => void;
};

export default function PriceCalculator({
  offer,
  formData,
  onUpdateRetailPrice,
}: Props) {
  const { t } = useTranslation();

  const { config, tenderDetail } = useOfferContext();

  const { data, isLoading, mutate } = usePriceCalculator({
    offerId: offer.uuid as string,
    callback: onUpdateRetailPrice,
  });

  const onCalculatePrice = () => {
    const selectionDate = tenderDetail
      ?.values
      ?.[PimAttributes.SELECTION_DATE]?.data as unknown as string;

    let startDate = dayjs().add(1, 'month').format('YYYY-MM-DD');
    if (selectionDate) {
      startDate = dayjs(selectionDate).format('YYYY-MM-DD');
    }

    const body: PriceDataParams = formDataToPriceDataParams(startDate, formData);

    mutate(body);
  };

  const calculatorDisabled = useMemo(() => isPriceCalculationDisabled(
    formData,
    config?.ethylMildLimit,
    config?.mildAlcoholLimit,
  ), [formData, config]);

  if (calculatorDisabled) {
    return null;
  }

  return (
    <div className="price_calculator">
      <div className="price_calculator__header">
        <span>{t('prices.label-price-calculator')}</span>
        <Button
          label={t('prices.cmd-calculate-price')}
          severity="success"
          icon="pi pi-calculator"
          size="small"
          type="button"
          onClick={onCalculatePrice}
          loading={isLoading}
        />
      </div>
      <PriceResult data={data} isLoading={isLoading} attributes={visibleAttributes} />
    </div>
  );
}
