import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { SystemMessageDto } from '../api/_generated_';
import useAxiosPrivate from './useAxiosPrivate';
import { SYSTEM_MESSAGES } from '../constants/queryKeys';

export default function useSystemMessages() {
  const { i18n } = useTranslation();

  const axios = useAxiosPrivate();

  async function queryFn() {
    const res = await axios.get<SystemMessageDto[]>(`/system-messages?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: [SYSTEM_MESSAGES, i18n.language],
    queryFn,
  });
}
